/* Alunos.css */
.alunos-container {
    background: #1d1d1d;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: white;
}

.table-container {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}
