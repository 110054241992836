/* Exercicios.css */
.exercicios-container {
    background: #1d1d1d;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: white;
}

.exercicios-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    color: white;
}

.exercicios-table th,
.exercicios-table td {
    border: 1px solid #444;
    padding: 8px;
    text-align: left;
}

.exercicios-table th {
    background-color: #333;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #1d1d1d;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
